import { getConfig } from '@util/config';
import { AvatarImageUploadResponse } from '@api/user/UserApiTypes';
import { ApiResponse, DjangoError } from '@api/ApiTypes';
import Experiment, { ExperimentDataType, getExperimentDataTypeSlug } from '@models/Experiment';
import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { UserAuthentication } from '@models/User';
import { datadogLogs } from '@datadog/browser-logs';
import { UnitShortname } from '@models/ExperimentType';
import { isNotBlank } from '@util/StringUtil';
import Endpoints from '@services/Endpoints';
import Logger from '@util/Logger';
import { ExperimentAnalysis } from '@models/analysis/ExperimentAnalysis';
import { AnalysisShortname } from '@models/analysis/AnalysisType';
import Plot from '@models/Plot';
import { isDefined } from '@util/TypeGuards';
import {
    ImageAnalysisFormValues,
    SpreadsheetAnalysisFormValues,
    PrismAnalysisFormValues,
    ExternalAnalysisFormValues,
} from '@components/experiments/analyses/AnalysisFormTypes';
import { TokenInfo } from '@contexts/AuthContext';
import { ProteinProteinInteractionAnalysis } from '../models/analysis/ProteinProteinInteractionAnalysis';
import { OverlapAnalysis, OverlapAnalysisInput } from '../models/analysis/OverlapAnalysis';
import { ExperimentAnalysisInput } from '../models/analysis/ExperimentAnalysisInput';

const logger = Logger.make('ApiService');

export type ProgressEventInfo = { percentage: number; total: number; loaded: number };
type ConstructorParams = UserAuthentication & { getTokens?: () => TokenInfo | null };
/**
 * A class to handle fetching data from the Pluto API. This class wil handle authenticating requests.
 * As the number of API requests grows this class should probably be separated out into smaller classes grouped by functionality.
 */
export default class ApiService {
    static withTokens(args: ConstructorParams) {
        return new ApiService(args);
    }

    getTokens?: () => TokenInfo | null;
    _accessToken: string | null = null;

    get accessToken(): string | null {
        return this.getTokens?.()?.access_token ?? this._accessToken ?? null;
    }

    $axios: AxiosInstance;

    constructor(params?: ConstructorParams) {
        this._accessToken = params?.access_token ?? null;
        this.getTokens = params?.getTokens;
        this.buildFetcher = this.buildFetcher.bind(this);
        this.$axios = axios.create({
            baseURL: this.baseUrl,
        });
    }

    standardHeaders: Record<string, string> = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    };

    get config() {
        return getConfig();
    }

    get baseUrl(): string {
        return this.config.api.host;
    }

    get authHeaders(): Record<string, string> {
        return { Authorization: this.accessToken ? `Bearer ${this.accessToken}` : '' };
    }

    get standardAuthHeaders(): Record<string, string> {
        return { ...this.standardHeaders, ...this.authHeaders };
    }

    /**
     * Get response or throw error
     * @param {string} path
     * @return {Promise<T>}
     */
    async buildFetcher<T>(path: string): Promise<T> {
        const response = await fetch(`${this.baseUrl}${path}`, {
            headers: this.standardAuthHeaders,
        });
        return response.json();
    }

    async uploadAvatar(
        file: File,
        params: { onProgress?: (e: ProgressEventInfo) => void; endpoint?: string; filename?: string },
    ): Promise<AvatarImageUploadResponse | ApiResponse<string, DjangoError> | null> {
        const formData = new FormData();
        formData.append(params.filename ?? 'file', file);
        const endpoint = params.endpoint ?? Endpoints.avatars();
        const TIMER_LABEL = '[ApiService] Upload Avatar duration';
        console.time(TIMER_LABEL);
        try {
            const response = await this.$axios.post<FormData, AxiosResponse<AvatarImageUploadResponse>>(
                endpoint,
                formData,
                {
                    headers: this.authHeaders,
                    onUploadProgress: (e) => {
                        logger.info('api service upload progress event', e);
                        const event: ProgressEventInfo = {
                            loaded: e.loaded,
                            total: e.total,
                            percentage: e.total > 0 ? e.loaded / e.total : 0,
                        };
                        params.onProgress?.(event);
                    },
                },
            );
            logger.info('Avatar upload completed successfully');
            return response.data;
        } catch (error) {
            logger.error(error);
            datadogLogs.logger.error('Avatar upload error - ', {}, error);
            const status = (error as AxiosError).response?.status ?? 500;
            const body = (error as DjangoError) ?? {
                message: error.message,
                code: 'Internal Server Error',
            };
            return { error: body, isError: true, status };
        } finally {
            console.timeEnd(TIMER_LABEL);
        }

        return null;
    }

    async uploadBiomarkers(params: {
        file: File;
        slug: string;
        orgId: string;
    }): Promise<any | ApiResponse<string, DjangoError> | null> {
        const formData = new FormData();
        formData.append('file', params.file);
        formData.append('organization_uuid', params.orgId);
        const endpoint = Endpoints.lab.biomarkerCSVUpload(params.slug);
        try {
            const response = await this.$axios.post<FormData, AxiosResponse<AvatarImageUploadResponse>>(
                endpoint,
                formData,
                {
                    headers: {
                        ...this.authHeaders,
                        'Content-Type': 'multipart/form-data',
                    },
                },
            );
            logger.info('Biomarkers upload completed successfully');
            return response.data;
        } catch (error) {
            logger.error(error);
            datadogLogs.logger.error('Biomarkers upload error - ', {}, error);
            const status = (error as AxiosError).response?.status ?? 500;
            const body = (error as DjangoError) ?? {
                message: error.message,
                code: 'Internal Server Error',
            };
            return { error: body, isError: true, status };
        }
    }

    /**
     * Upload data files for a given experiment id. Only one file is supported at a time.
     * @param {{dataType: ExperimentDataType, file: File, experimentId: string}} params
     * @return {Promise<void>}
     */
    async uploadExperimentFiles(params: {
        dataType: ExperimentDataType;
        file: File;
        experimentId: string;
        units?: UnitShortname | string | null;
        units_display_name?: string | null;
        onProgress?: (e: ProgressEventInfo) => void;
    }): Promise<ApiResponse<string, DjangoError>> {
        const { file, dataType, experimentId, units, units_display_name } = params;
        const formData = new FormData();
        formData.append('file', file);
        if (isNotBlank(units)) {
            formData.append('units', units);
        }
        if (isNotBlank(units_display_name)) {
            formData.append('units_display_name', units_display_name);
        }
        const slug = getExperimentDataTypeSlug(dataType);
        const path = `/lab/experiments/${experimentId}/${slug}/`;

        try {
            const response = await this.$axios.post(path, formData, {
                headers: this.authHeaders,
                onUploadProgress: (e) => {
                    logger.info('api service upload progress event', e);
                    const event: ProgressEventInfo = {
                        loaded: e.loaded,
                        total: e.total,
                        percentage: e.total > 0 ? e.loaded / e.total : 0,
                    };
                    params.onProgress?.(event);
                },
            });

            return { success: 'uploaded file successfully', isError: false, status: response.status };
        } catch (error) {
            logger.error(error);
            const status = (error as AxiosError).response?.status ?? 500;
            const body = (error as AxiosError<DjangoError>).response?.data ?? {
                message: error.message,
                code: 'Internal Server Error',
            };
            return { error: body, isError: true, status };
        }
    }

    /**
     * Upload data for experiment analysis
     * @param {{dataType: ExperimentDataType, file: File, experimentId: string}} params
     * @return {Promise<void>}
     */
    async uploadImageAnalysis(params: {
        values: Required<ImageAnalysisFormValues>;
        experiment: Experiment;
        plot?: Plot | null;
        onProgress?: (e: ProgressEventInfo) => void;
    }): Promise<ExperimentAnalysis> {
        const { experiment, values, plot } = params;
        const formData = new FormData();
        const analysisType: AnalysisShortname = 'image';
        formData.append('analysis_type', analysisType);
        // If image exists, and is a file, then we update with that file. Otherwise the image doesn't change
        if (isDefined(values.image) && typeof values.image !== 'string') {
            formData.append('image', values.image as File);
        }

        if (isDefined(values.methods)) {
            formData.append('methods', values.methods ?? '');
        }

        values.targets.forEach((target) => {
            formData.append('targets', `${target}`);
        });

        // do update
        if (plot?.analysis) {
            logger.info('updating analysis with PUT');
            const path = Endpoints.lab.experiment.analysis.base({
                experimentId: experiment.uuid,
                analysisId: plot.analysis.uuid,
            });
            const response = await this.$axios.put<ExperimentAnalysis>(path, formData, {
                headers: this.authHeaders,
                onUploadProgress: (e) => {
                    logger.info('api service upload progress event', e);
                    const event: ProgressEventInfo = {
                        loaded: e.loaded,
                        total: e.total,
                        percentage: e.total > 0 ? e.loaded / e.total : 0,
                    };
                    params.onProgress?.(event);
                },
            });
            return response.data;
        }
        logger.info('creating new analysis');
        const path = Endpoints.lab.experiment.analyses(experiment.uuid);
        const response = await this.$axios.post<ExperimentAnalysis>(path, formData, {
            headers: this.authHeaders,
            onUploadProgress: (e) => {
                logger.info('api service upload progress event', e);
                const event: ProgressEventInfo = {
                    loaded: e.loaded,
                    total: e.total,
                    percentage: e.total > 0 ? e.loaded / e.total : 0,
                };
                params.onProgress?.(event);
            },
        });

        return response.data;
    }

    /**
     * Upload data for experiment analysis
     * @param {{dataType: ExperimentDataType, file: File, experimentId: string}} params
     * @return {Promise<void>}
     */
    async uploadSpreadsheetAnalysis(params: {
        values: Required<SpreadsheetAnalysisFormValues>;
        experiment: Experiment;
        plot?: Plot | null;
        onProgress?: (e: ProgressEventInfo) => void;
    }): Promise<ExperimentAnalysis> {
        const { experiment, values, plot } = params;
        const formData = new FormData();
        const analysisType: AnalysisShortname = 'spreadsheet';
        formData.append('analysis_type', analysisType);
        // If image exists, and is a file, then we update with that file. Otherwise the image doesn't change
        if (
            isDefined(values.spreadsheet) &&
            (!plot?.analysis || (typeof values.spreadsheet === 'string' && !values.spreadsheet.length))
        ) {
            formData.append('spreadsheet', values.spreadsheet as File);
        }

        if (isDefined(values.methods)) {
            formData.append('methods', values.methods ?? '');
        }

        // do update
        if (plot?.analysis) {
            logger.info('updating analysis with PUT');
            const path = Endpoints.lab.experiment.analysis.base({
                experimentId: experiment.uuid,
                analysisId: plot.analysis.uuid,
            });
            const response = await this.$axios.put<ExperimentAnalysis>(path, formData, {
                headers: this.authHeaders,
                onUploadProgress: (e) => {
                    logger.info('api service upload progress event', e);
                    const event: ProgressEventInfo = {
                        loaded: e.loaded,
                        total: e.total,
                        percentage: e.total > 0 ? e.loaded / e.total : 0,
                    };
                    params.onProgress?.(event);
                },
            });
            return response.data;
        }
        const path = Endpoints.lab.experiment.analyses(experiment.uuid);
        const response = await this.$axios.post<ExperimentAnalysis>(path, formData, {
            headers: this.authHeaders,
            onUploadProgress: (e) => {
                logger.info('api service upload progress event', e);
                const event: ProgressEventInfo = {
                    loaded: e.loaded,
                    total: e.total,
                    percentage: e.total > 0 ? e.loaded / e.total : 0,
                };
                params.onProgress?.(event);
            },
        });

        return response.data;
    }

    /**
     * Upload data for experiment analysis
     * @param {{dataType: ExperimentDataType, file: File, experimentId: string}} params
     * @return {Promise<void>}
     */
    async uploadPrismAnalysis(params: {
        values: Required<PrismAnalysisFormValues>;
        experiment: Experiment;
        plot?: Plot | null;
        onProgress?: (e: ProgressEventInfo) => void;
    }): Promise<ExperimentAnalysis> {
        const { experiment, values, plot } = params;
        const formData = new FormData();
        const analysisType: AnalysisShortname = 'prism';
        const analysisName = 'Prism';
        formData.append('analysis_type', analysisType);
        formData.append('name', analysisName);
        if (isDefined(values.experiment_file_id)) {
            formData.append('experiment_file_id', values.experiment_file_id);
        }

        if (isDefined(values.methods)) {
            formData.append('methods', values.methods ?? '');
        }

        // do update
        if (plot?.analysis) {
            logger.info('updating analysis with PUT');
            const path = Endpoints.lab.experiment.analysis.base({
                experimentId: experiment.uuid,
                analysisId: plot.analysis.uuid,
            });
            const response = await this.$axios.put<ExperimentAnalysis>(path, formData, {
                headers: this.authHeaders,
                onUploadProgress: (e) => {
                    logger.info('api service upload progress event', e);
                    const event: ProgressEventInfo = {
                        loaded: e.loaded,
                        total: e.total,
                        percentage: e.total > 0 ? e.loaded / e.total : 0,
                    };
                    params.onProgress?.(event);
                },
            });
            return response.data;
        }
        logger.info('creating new analysis');
        const path = Endpoints.lab.experiment.analyses(experiment.uuid);
        const response = await this.$axios.post<ExperimentAnalysis>(path, formData, {
            headers: this.authHeaders,
            onUploadProgress: (e) => {
                logger.info('api service upload progress event', e);
                const event: ProgressEventInfo = {
                    loaded: e.loaded,
                    total: e.total,
                    percentage: e.total > 0 ? e.loaded / e.total : 0,
                };
                params.onProgress?.(event);
            },
        });

        return response.data;
    }

    /**
     * Upload data for experiment analysis
     * @param {{dataType: ExperimentDataType, file: File, experimentId: string}} params
     * @return {Promise<void>}
     */
    async uploadExternalAnalysis(params: {
        values: Required<ExternalAnalysisFormValues>;
        experiment: Experiment;
        plot?: Plot | null;
        onProgress?: (e: ProgressEventInfo) => void;
    }): Promise<ExperimentAnalysis> {
        const { experiment, values, plot } = params;
        const formData = new FormData();
        const analysisType: AnalysisShortname = 'external';
        const analysisName = 'External';
        formData.append('analysis_type', analysisType);
        formData.append('name', analysisName);
        formData.append('origin', 'web');
        if (isDefined(values.display_file_id)) {
            formData.append('display_file_id', values.display_file_id);
        }
        if (isDefined(values.results_file_id)) {
            formData.append('results_file_id', values.results_file_id);
        }
        if (isDefined(values.script_file_id)) {
            formData.append('script_file_id', values.script_file_id);
        }
        if (isDefined(values.methods)) {
            formData.append('methods', values.methods ?? '');
        }

        // do update
        if (plot?.analysis) {
            logger.info('updating analysis with PUT');
            const path = Endpoints.lab.experiment.analysis.base({
                experimentId: experiment.uuid,
                analysisId: plot.analysis.uuid,
            });
            const response = await this.$axios.put<ExperimentAnalysis>(path, formData, {
                headers: this.authHeaders,
                onUploadProgress: (e) => {
                    logger.info('api service upload progress event', e);
                    const event: ProgressEventInfo = {
                        loaded: e.loaded,
                        total: e.total,
                        percentage: e.total > 0 ? e.loaded / e.total : 0,
                    };
                    params.onProgress?.(event);
                },
            });
            return response.data;
        }
        logger.info('creating new analysis');
        const path = Endpoints.lab.experiment.analyses(experiment.uuid);
        const response = await this.$axios.post<ExperimentAnalysis>(path, formData, {
            headers: this.authHeaders,
            onUploadProgress: (e) => {
                logger.info('api service upload progress event', e);
                const event: ProgressEventInfo = {
                    loaded: e.loaded,
                    total: e.total,
                    percentage: e.total > 0 ? e.loaded / e.total : 0,
                };
                params.onProgress?.(event);
            },
        });

        return response.data;
    }

    /**
     * Uploads a protein-protein interaction analysis for an experiment.
     *
     * @param params - The parameters for the analysis upload.
     * @param params.values - The required form values for the analysis.
     * @param params.experiment - The experiment to associate the analysis with.
     * @param params.onProgress - Optional callback function to track progress.
     * @returns A promise that resolves to the experiment analysis or an API response with an error.
     */
    async uploadProteinProteinAnalysis(params: {
        values: Partial<ProteinProteinInteractionAnalysis>;
        experiment: Experiment;
        onProgress?: (e: ProgressEventInfo) => void;
    }): Promise<ExperimentAnalysis> {
        const { experiment, values } = params;
        const formData = new FormData();
        const analysisType: AnalysisShortname = 'protein_protein_interaction';
        const analysisCategory = 'pathway';
        formData.append('analysis_type', analysisType);
        formData.append('category', analysisCategory);
        if (isDefined(values.target_genes_format)) {
            formData.append('target_genes_format', values.target_genes_format);
        }
        if (isDefined(values.network_type)) {
            formData.append('network_type', values.network_type);
        }
        if (isDefined(values.targets_csv)) {
            formData.append('targets_csv', values.targets_csv);
        }
        if (isDefined(values.targets_csv_filename)) {
            formData.append('targets_csv_filename', values.targets_csv_filename);
        }

        const endpoint = Endpoints.lab.experiment.analyses(experiment.uuid);
        const response = await this.$axios.post<FormData, AxiosResponse<ExperimentAnalysis>>(endpoint, formData, {
            headers: {
                ...this.authHeaders,
                'Content-Type': 'multipart/form-data',
            },
        });

        return response.data;
    }

    async putOverlapAnalysis(params: {
        values: Partial<OverlapAnalysis>;
        experiment: Experiment;
        plot?: Plot | null;
        onProgress?: (e: ProgressEventInfo) => void;
    }): Promise<ExperimentAnalysis> {
        const { experiment, values, plot } = params;

        const endpoint = Endpoints.lab.experiment.analysis.base({
            experimentId: experiment.uuid,
            analysisId: plot?.analysis?.uuid ?? '',
        });

        const response = await this.$axios.put<FormData, AxiosResponse<ExperimentAnalysis>>(endpoint, values, {
            headers: this.authHeaders,
        });

        return response.data;
    }

    async putOverlapAnalysisInput(params: {
        values: Partial<OverlapAnalysisInput>;
        experimentId: string;
        plot?: Plot | null;
        onProgress?: (e: ProgressEventInfo) => void;
        analysisInputId: string;
    }): Promise<ExperimentAnalysisInput | null> {
        const { experimentId, values, plot, analysisInputId } = params;
        const formData = new FormData();
        const useFormData = values.targets_csv !== null && values.targets_csv !== undefined;

        if (useFormData) {
            Object.entries(values).forEach(([key, value]) => {
                if (value !== null && value !== undefined && (typeof value === 'string' ? isDefined(value) : true)) {
                    formData.append(key, value as string | Blob);
                }
            });
        }

        // do update
        if (plot?.analysis) {
            logger.info('updating analysis input with PUT');
            const path = Endpoints.lab.experiment.analysis.input({
                experimentId,
                analysisId: plot.analysis.uuid,
                analysisInputId,
            });
            const payload = useFormData ? formData : values;
            const headers = useFormData
                ? {
                      ...this.authHeaders,
                      'Content-Type': 'multipart/form-data',
                  }
                : this.authHeaders;
            const response = await this.$axios.put<ExperimentAnalysisInput>(path, payload, {
                headers,
            });
            return response.data;
        }
        return null;
    }

    async uploadFileChunk({ url, file }: { url: string; file: File }) {
        const MAX_CHUNK_SIZE = 256 * 1024; // multiples of 256 KiB
        const totalSize = file.size;
        let chunkIndex = 0;
        const totalChunks = Math.ceil(totalSize / MAX_CHUNK_SIZE);
        logger.info(`going to need ${totalChunks} chunks`);
        const uploadChunk = async (chunkIndex: number) => {
            const chunkSize = Math.min(MAX_CHUNK_SIZE, totalSize);
            const chunkFirstByte = chunkIndex * chunkSize;
            const chunkLastByte = Math.min(chunkSize, totalSize) - 1;
            const formData = new FormData();
            formData.append('file', file);
            const range = `bytes ${chunkFirstByte}-${chunkLastByte}/*`;
            logger.info(`uploading chunk ${chunkIndex} with range ${range}`);
            try {
                const response = await axios({
                    url,
                    method: 'put',
                    data: file,
                    headers: {
                        // 'Content-Length': chunkSize,
                        'Content-Range': range,
                        // 'Content-Type': file.type,
                    },
                    withCredentials: false,
                });
                const hasMore = response.status === 308; // resume incomplete
                logger.info(`chunk ${chunkIndex} (status = ${response.status}) response`, response);
                return { completed: !hasMore };
            } catch (error) {
                logger.error(`failed to upload chunk ${chunkIndex}`);
                logger.error(error);
                return { error, completed: false };
            }
        };

        let isFinished = false;
        let uploadError: unknown | null = null;
        while (!isFinished && !uploadError) {
            const { completed, error } = await uploadChunk(chunkIndex);
            isFinished = completed;
            uploadError = error;
            chunkIndex++;
        }
    }
}
