import { ExperimentAnalysis } from '@models/analysis/ExperimentAnalysis';
import { GeneSetEnrichmentAnalysis } from '@models/analysis/GeneSetEnrichmentAnalysis';
import { ImageAnalysis } from '@models/analysis/ImageAnalysis';
import { SpreadsheetAnalysis } from '@models/analysis/SpreadsheetAnalysis';
import { PrismAnalysis } from '@models/analysis/PrismAnalysis';
import { PrincipalComponentsAnalysis } from '@models/analysis/PrincipalComponentsAnalysis';
import { TextAnalysis } from '@models/analysis/TextAnalysis';
import { TranscriptionFactorEnrichmentAnalysis } from '@models/analysis/TranscriptionFactorEnrichmentAnalysis';
import { ExternalAnalysis } from '@models/analysis/ExternalAnalysis';
import { SeuratOverRepresentationAnalysis } from './SeuratOverRepresentationAnalysis';
import { ProteinProteinInteractionAnalysis } from './ProteinProteinInteractionAnalysis';
import { SampleCorrelationAnalysis } from './SampleCorrelationAnalysis';
import { OverlapAnalysis } from './OverlapAnalysis';

export const isImageAnalysis = (analysis: ExperimentAnalysis | null): analysis is ImageAnalysis => {
    if (!analysis) {
        return false;
    }
    return analysis.analysis_type === 'image';
};

export const isSpreadsheetAnalysis = (analysis: ExperimentAnalysis | null): analysis is SpreadsheetAnalysis => {
    if (!analysis) {
        return false;
    }
    return analysis.analysis_type === 'spreadsheet';
};

export const isPrismAnalysis = (analysis: ExperimentAnalysis | null | undefined): analysis is PrismAnalysis => {
    if (!analysis) {
        return false;
    }
    return analysis.analysis_type === 'prism';
};

export const isExternalAnalysis = (analysis: ExperimentAnalysis | null | undefined): analysis is ExternalAnalysis => {
    if (!analysis) {
        return false;
    }
    return analysis.analysis_type === 'external';
};

export const isTextAnalysis = (analysis: ExperimentAnalysis | null | undefined): analysis is TextAnalysis => {
    return analysis?.analysis_type === 'text';
};

export const isGeneSetEnrichmentAnalysis = (
    analysis: ExperimentAnalysis | null | undefined,
): analysis is GeneSetEnrichmentAnalysis => {
    return analysis?.analysis_type === 'gene_set_enrichment';
};

export const isSeuratOverRepresentationAnalysis = (
    analysis: ExperimentAnalysis | null | undefined,
): analysis is SeuratOverRepresentationAnalysis => {
    return analysis?.analysis_type === 'seurat_over_representation';
};

export const isPrincipalComponentsAnalysis = (
    analysis: ExperimentAnalysis | null,
): analysis is PrincipalComponentsAnalysis => {
    if (!analysis) {
        return false;
    }
    return analysis.analysis_type === 'principal_components';
};

export const isTranscriptionFactorEnrichmentAnalysis = (
    analysis: ExperimentAnalysis | null | undefined,
): analysis is TranscriptionFactorEnrichmentAnalysis => {
    return analysis?.analysis_type === 'transcription_factor_enrichment';
};

export const isProteinProteinInteractionAnalysis = (
    analysis: ExperimentAnalysis | null | undefined,
): analysis is ProteinProteinInteractionAnalysis => {
    return analysis?.analysis_type === 'protein_protein_interaction';
};

export const isSampleCorrelationAnalysis = (
    analysis: ExperimentAnalysis | null | undefined,
): analysis is SampleCorrelationAnalysis => {
    return analysis?.analysis_type === 'sample_correlation';
};

export const isOverlapAnalysis = (analysis: ExperimentAnalysis | null | undefined): analysis is OverlapAnalysis => {
    return analysis?.analysis_type === 'overlap';
};
