import getNextConfig from 'next/config';
import { PublicRuntimeConfig } from '@util/configTypes';
import { isNotBlank } from '@util/StringUtil';

declare global {
    interface Window {
        getPlutoConfig: () => PublicConfig;
        getNextRuntimeConfig: () => ReturnType<typeof getNextConfig>;
    }
}

export const isBrowser = () => typeof window === 'object';
export const isServer = () => typeof window === 'undefined';

const defaultConfig: PublicRuntimeConfig = {
    accessTokenExpirationRefreshBufferMs: '',
    accessTokenTtlMs: '',
    refreshTokenTtlMs: '',
    googleClientId: '',
    googleTagManagerId: '',
    googleTagManagerAppId: '',
    apiHost: '',
    webHost: '',
    plutoHelpUrl: '',
    datadogEnv: 'dev',
    appVersion: '',
    branchName: '',
    isDev: true,
    isProd: false,
    invitesRefreshMs: 0,
    openAIAnnotationKey: '',
};

/**
 * Get configs for the app, driven by environment variables. This object contains values that should be safe for use
 * @return {PublicConfig}
 */
export function getConfig() {
    const config = getNextConfig();
    const { publicRuntimeConfig = defaultConfig } = config || {};
    const {
        accessTokenExpirationRefreshBufferMs,
        accessTokenTtlMs,
        refreshTokenTtlMs,
        googleClientId,
        googleTagManagerId,
        googleTagManagerAppId,
        apiHost,
        webHost,
        plutoHelpUrl,
        datadogEnv,
        appVersion,
        branchName,
        isDev,
        isProd,
        invitesRefreshMs,
        openAIAnnotationKey,
    }: PublicRuntimeConfig = publicRuntimeConfig;
    return {
        env: {
            isDev,
            isProd,
            name: datadogEnv,
        },
        api: {
            host: apiHost,
        },
        web: {
            host: webHost,
            helpUrl: plutoHelpUrl,
        },
        auth: {
            accessTokenExpiresAfterMs: Number(accessTokenTtlMs),
            refreshTokenExpiresAfterMs: Number(refreshTokenTtlMs),
            accessTokenRefreshBufferMs: Number(accessTokenExpirationRefreshBufferMs),
            googleClientId: googleClientId,
        },
        analytics: {
            googleTagManagerId: googleTagManagerId,
            googleTagManagerAppId: googleTagManagerAppId,
        },
        datadog: {
            env: datadogEnv,
        },
        appVersion,
        branchName,
        invitesRefreshMs,
        vercel: {
            isVercelEnv: isNotBlank(process.env.NEXT_PUBLIC_VERCEL_ENV),
            isPreview: process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview',
        },
        openAI: {
            openAIAnnotationKey: openAIAnnotationKey,
        },
    };
}

/**
 * Get configs that can be used on the server, for example in getStaticProps. This may contain secret values.
 * @return {{}}
 */
export function getPrivateConfig() {
    if (isBrowser()) {
        throw new Error(
            'Attempting to fetch private config from the browser. This is not allowed. No values will be returned.',
        );
    }
    const {} = getNextConfig();
    return {};
}

if (isBrowser()) {
    window.getPlutoConfig = getConfig;
    window.getNextRuntimeConfig = getNextConfig;
}

/**
 * Returns a type safe window object. On the server this will be null
 * @returns {null | Window}
 */
export const getWindow = (): Window | null => {
    if (typeof window === 'object') {
        return window;
    }
    return null;
};

export type PublicConfig = ReturnType<typeof getConfig>;
export type PrivateConfig = ReturnType<typeof getPrivateConfig>;
