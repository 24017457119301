import { allEnumStringValues } from '@util/ObjectUtil';
import { isDefined } from '@util/TypeGuards';
import { isBrowser } from '@util/config';

const logger = console;

export enum StorageKey {
    LOG_LEVEL = 'pluto.log.level',
    LOG_NETWORK = 'pluto.log.network',
    LOG_AUTH_TOKEN_STATUS = 'pluto.log.auth_token_status',
    LOG_FILE_UPLOAD = 'pluto.log.file_upload',
    MODE = 'pluto.mode',
    AUTH_USER_ID = 'pluto.auth.user_id',
    FEATURE_TOGGLES = 'pluto.features',
    SAML_CONFIG = 'pluto.saml.org',
    GALLERY_LAYOUT = 'pluto.gallery.layout',
    BANNER_DISMISSED_JPM24 = 'pluto.banner.JPM24.dismissed',
    DASHBOARD_SIDEBAR_EXPANDED = 'pluto.dashboard.sidebar.expanded',
    CANVAS_NODES = 'pluto.canvas.nodes',
    CANVAS_EDGES = 'pluto.canvas.edges',
    CANVAS_PANES = 'pluto.canvas.panes',
    CANVAS_VIEWPORT = 'pluto.canvas.viewport',
}

export const storageKeys = allEnumStringValues<StorageKey>(StorageKey);

export const isStorageKeyName = (key?: string | null): key is StorageKey => {
    return isDefined(key) && (storageKeys as string[]).includes(key);
};

export const isStorageKeyValue = (key?: string | null): key is StorageKey => {
    return isDefined(key) && Object.values<string>(StorageKey).includes(key);
};

export class LocalStorageService {
    static getAllKeys(): string[] {
        if (!isBrowser()) {
            return [];
        }
        return Object.keys(localStorage);
    }

    static getItem(key: StorageKey | string, fallback: string | null = null): string | null {
        if (!isBrowser()) {
            return fallback;
        }
        try {
            return localStorage.getItem(key) ?? fallback;
        } catch (error) {
            logger.error(error);
            return fallback;
        }
    }

    static removeItem(key: StorageKey): void {
        if (!isBrowser()) {
            return;
        }
        try {
            localStorage.removeItem(key);
        } catch (error) {
            logger.error(error);
        }
    }

    static setItem(key: StorageKey | string, value: string | null | undefined): void {
        if (!isBrowser()) {
            return;
        }
        try {
            localStorage.setItem(key, value ?? '');
        } catch (error) {
            logger.error(error);
        }
    }

    static setObject<T extends Record<string, unknown> | unknown>(key: StorageKey | string, value: T | null): void {
        if (!isBrowser()) {
            return;
        }
        try {
            const encoded = JSON.stringify(value);
            LocalStorageService.setItem(key, encoded);
        } catch (error) {
            logger.error(error);
        }
    }

    static getObject<T extends Record<string, unknown> | unknown>(
        key: StorageKey | string,
        fallback?: T | null | undefined,
    ): T | null | undefined {
        if (!isBrowser()) {
            return fallback;
        }
        try {
            const encoded = LocalStorageService.getItem(key);
            if (!isDefined(encoded)) {
                return fallback;
            }
            return JSON.parse(encoded);
        } catch (error) {
            logger.error(error);
            return fallback;
        }
    }

    static isDevMode(): boolean {
        if (!isBrowser()) {
            return false;
        }
        try {
            return localStorage.getItem('pluto.mode') === 'dev';
        } catch (error) {
            logger.error(error);
            return false;
        }
    }

    static setDevMode(isDev: boolean): void {
        if (!isBrowser()) {
            return;
        }
        if (isDev) {
            LocalStorageService.setItem(StorageKey.MODE, 'dev');
        } else {
            LocalStorageService.removeItem(StorageKey.MODE);
        }
    }
}

export default LocalStorageService;
