import { debounce } from 'debounce';
import { datadogLogs } from '@datadog/browser-logs';
import LocalStorageService, { StorageKey } from '@util/LocalStorageService';
import { isBrowser } from '@util/config';
import * as Sentry from '@sentry/react';

declare global {
    const __filebasename: string;
}

export type MessageArg = string | number | object | null | Error | unknown;

export type Level = 'debug' | 'info' | 'warn' | 'error';

const LevelMap: Record<Level, number> = {
    debug: 0,
    info: 1,
    warn: 2,
    error: 3,
};

const getLogNetworkTraffic = debounce(
    () => {
        const value: string | null | boolean = LocalStorageService.getItem(StorageKey.LOG_NETWORK, 'false') ?? 'false';
        return `${value}`.toLowerCase() === 'true';
    },
    1000,
    true,
);

const getLogAuthTokenStatus = debounce(
    () => {
        const value: string | null | boolean =
            LocalStorageService.getItem(StorageKey.LOG_AUTH_TOKEN_STATUS, 'false') ?? 'false';
        return `${value}`.toLowerCase() === 'true';
    },
    1000,
    true,
);

const getLogFileUploadStatus = debounce(
    () => {
        const value: string | null | boolean =
            LocalStorageService.getItem(StorageKey.LOG_FILE_UPLOAD, 'false') ?? 'false';
        return `${value}`.toLowerCase() === 'true';
    },
    1000,
    true,
);

const getConfigLevel = debounce(
    (): Level => {
        if (!isBrowser()) {
            return 'debug';
        }
        const savedKey = LocalStorageService.getItem(StorageKey.LOG_LEVEL, 'warn') ?? 'warn';
        if (savedKey && Object.keys(LevelMap).includes(savedKey)) {
            return savedKey as Level;
        }
        return 'warn';
    },
    1000,
    true,
);

export type LogFeature = 'network_traffic' | 'auth_status' | 'file_upload';

export default class Logger {
    prefix?: string;
    feature: LogFeature | null = null;

    constructor({ prefix, feature }: { prefix?: string; feature?: LogFeature | null }) {
        this.prefix = prefix;
        this.feature = feature ?? null;
    }

    static make(prefix: string = __filebasename, feature: LogFeature | null = null): Logger {
        return new Logger({ prefix, feature });
    }

    with(feature: LogFeature): Logger {
        return Logger.make(this.prefix, feature);
    }

    get level(): Level {
        return getConfigLevel();
    }

    isEnabled(level: Level): boolean {
        return LevelMap[this.level] <= LevelMap[level] && this.featureEnabled();
    }

    networkEnabled(): boolean {
        return getLogNetworkTraffic();
    }

    authStatusEnabled(): boolean {
        return getLogAuthTokenStatus();
    }

    fileUploadEnabled(): boolean {
        return getLogFileUploadStatus();
    }

    makePrefix() {
        const parts: unknown[] = [];
        parts.push(new Date().toISOString());
        if (this.prefix) {
            parts.push(`[${this.prefix}]`);
        }
        return `${parts.join(' ')}`;
    }

    debug(message?: MessageArg, ...data: unknown[]) {
        if (typeof message === 'string' && this.isEnabled('debug')) {
            console.debug.apply(console, [this.makePrefix(), message, ...data]);
        }
    }

    log(message?: MessageArg, ...data: unknown[]) {
        this.info(message, ...data);
    }

    info(message?: MessageArg, ...data: unknown[]) {
        if (typeof message === 'string' && this.isEnabled('info')) {
            console.info.apply(console, [this.makePrefix(), message, ...data]);
        }
    }

    warn(message?: MessageArg, ...data: unknown[]) {
        if (typeof message === 'string' && this.isEnabled('warn')) {
            console.warn.apply(console, [this.makePrefix(), message, ...data]);
            datadogLogs.logger.warn(message);
        }
    }

    error(message?: MessageArg, ...data: unknown[]) {
        if (typeof message === 'string' && this.isEnabled('error')) {
            console.error.apply(console, [this.makePrefix(), message, ...data]);
            datadogLogs.logger.error(message);
            Sentry.captureException(new Error(message));
        }
    }

    featureEnabled(): boolean {
        const feature = this.feature;

        let enabled;
        switch (feature) {
            case 'network_traffic':
                enabled = this.networkEnabled();
                break;
            case 'auth_status':
                enabled = this.authStatusEnabled();
                break;
            case 'file_upload':
                enabled = this.fileUploadEnabled();
                break;
            default:
                enabled = true;
        }
        return enabled;
    }
}
