export type ResponseErrorCode = 'invalid' | string;
export type FieldErrorCode = 'blank' | string;

export interface PlutoIdentifiable {
    pluto_id: string;
    uuid: string;
}

export interface DisplayableShortname<T extends string = string> {
    display_name: string;
    shortname: T;
}

export interface FieldError<M extends string = string> {
    message: M;
    code: FieldErrorCode;
}

export type GenericMessage = { message: FieldError };
export type CodedErrorMessage = { message: string; code: string };

export interface ErrorDetails<MESSAGE = GenericMessage> {
    code: FieldError;
    detail: FieldError;
    messages?: MESSAGE[];
    non_field_errors?: FieldError[];
    error_list?: string[];
    _errors: { message?: string }[];
}

export interface DjangoError<D = ErrorDetails> {
    code: ResponseErrorCode;
    message: string;
    details?: D;
}

export type ApiStatus<S> = { status: number; isError: boolean; success?: S };

export interface ApiErrorResponse<E> extends ApiStatus<never> {
    error: E | null;
    isError: true;
}

export interface ApiSuccessResponse<S> extends ApiStatus<S> {
    success: S;
    isError: false;
}

export function isApiError<S, E = DjangoError | null>(resp: ApiResponse<S, E>): resp is ApiErrorResponse<E> {
    return resp.isError;
}

export function isApiSuccess<S, E = DjangoError | null>(resp: ApiResponse<S, E>): resp is ApiSuccessResponse<S> {
    return !resp.isError;
}

export type ApiResponse<S, E = DjangoError | null> = ApiSuccessResponse<S> | ApiErrorResponse<E>;

export type ShareLevel = 'public' | 'shareable' | 'private';

/**
 * This will replace ShareLevel, i think (2021-07-27)
 */
export type AccessLevel = 'public' | 'shareable';

export function getShareSlug(level: ShareLevel = 'shareable'): string {
    switch (level) {
        case 'public':
            return 'public';
        default:
            return 'lab';
    }
}
