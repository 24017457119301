import React, { ErrorInfo, RefObject, ReactNode } from 'react';
import Logger from '@util/Logger';
import { ErrorOutline } from '@mui/icons-material';
import Button from '@components/Button';
import { CopyIcon } from '@components/icons/custom/CopyIcon';
import { datadogRum } from '@datadog/browser-rum';

const logger = Logger.make('PlutoErrorBoundary');

type Props = {
    children?: ReactNode;
};

type State = {
    hasError: boolean;
    errorMessage: string | null;
    showDetails: boolean;
    error: Error | null;
    copySuccess: boolean;
};

class PlutoErrorBoundary extends React.Component<Props, State> {
    errorRef: RefObject<HTMLParagraphElement>;

    constructor(props: Props) {
        super(props);
        this.state = { hasError: false, errorMessage: null, showDetails: false, error: null, copySuccess: false };
        this.errorRef = React.createRef<HTMLParagraphElement>();
    }

    static getDerivedStateFromError(error: Error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, errorMessage: error.message, error };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        logger.error(error, errorInfo);
        datadogRum.addError(error, { componentStack: errorInfo.componentStack });
    }

    async copyToClipboard(text: string) {
        // Do modern approach first, else fall back to deprecated method
        if (navigator.clipboard) {
            await navigator.clipboard.writeText(text);
            this.setState({ copySuccess: true });
            setTimeout(() => {
                this.setState({ copySuccess: false });
            }, 2500);
        } else {
            logger.error(new Error('Unsupported copy method - navigator.clipboard is undefined'));
        }
    }

    async copyErrorToClipboard() {
        try {
            const el = this.errorRef.current;
            if (!el) {
                logger.error(new Error('Unable to select text: no element selected'));
                return;
            }
            const range = document.createRange();
            range.selectNodeContents(el);
            const selection = window.getSelection();
            if (!selection) {
                logger.error(new Error('Unable to select text: no selection could be created'));
                return;
            }
            selection?.removeAllRanges();
            selection?.addRange(range);
            await this.copyToClipboard(this.state.error?.stack ?? this.state.error?.message ?? '');
        } catch (error) {
            logger.error(error);
        }
    }

    retry() {
        this.setState({ hasError: false, errorMessage: null, showDetails: false, error: null, copySuccess: false });
    }

    render() {
        const { hasError, showDetails, errorMessage, error, copySuccess } = this.state;
        if (hasError) {
            return (
                <>
                    <div className="flex flex-col justify-center px-8 py-12 text-center">
                        <div className="mx-auto mb-4 rounded-full bg-error p-4 text-error">
                            <ErrorOutline height={24} width={24} />
                        </div>
                        <h2 className="text-xl font-semibold tracking-tight">Whoops! Something went wrong...</h2>
                        <p className="text-base">
                            {errorMessage ??
                                'An unexpected error occurred while processing your plot data. Please try again later.'}
                        </p>
                        {error && (
                            <div className="mt-8">
                                <div className="mb-4 flex flex-col items-center justify-center space-y-4">
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        onClick={() => this.copyErrorToClipboard()}
                                        color="primary"
                                    >
                                        Retry
                                    </Button>
                                    <Button
                                        variant="text"
                                        size="small"
                                        onClick={() => this.setState({ showDetails: !showDetails })}
                                    >
                                        {showDetails ? 'Hide error details' : 'Show error details'}
                                    </Button>
                                </div>
                                {showDetails && error && (
                                    <div className="w-full space-y-4 text-left">
                                        <p
                                            ref={this.errorRef}
                                            className="w-full overflow-auto whitespace-pre px-4 py-2 font-mono text-error"
                                        >
                                            {error.stack}
                                        </p>
                                        <div>
                                            <Button
                                                variant="outlined"
                                                startIcon={<CopyIcon width={18} />}
                                                size="small"
                                                onClick={() => this.copyErrorToClipboard()}
                                                color="primary"
                                            >
                                                {copySuccess ? 'Copied!' : 'Copy to clipboard'}
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </>
            );
        }

        return this.props.children;
    }
}

export default PlutoErrorBoundary;
