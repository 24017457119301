import { Creatable, Filterable } from '@models/SelectableTypes';
import { DisplayableShortname } from '@api/ApiTypes';
import AnalysisType, { AnalysisShortname } from '@models/analysis/AnalysisType';
import InsightsAdminPhase from '@models/InsightsAdminPhase';
import { Organism } from '@models/Organism';

export type UnitShortname = 'raw_counts' | 'rsem_counts' | 'tpm' | 'rpkm' | 'rma_normalized_intensity';
export type MeasurementUnit = DisplayableShortname<UnitShortname>;

export type TargetTypeShortname = 'gene' | 'transcript' | 'protein' | 'metabolite' | 'other';
export type TargetType = DisplayableShortname<TargetTypeShortname>;

export enum ExperimentTypeShortname {
    rnaseq = 'rnaseq',
    scrnaseq = 'scrnaseq',
    simple = 'simple',
    microarray = 'microarray',
    metabolomics = 'metabolomics',
    proteomics = 'proteomics',
    chipseq = 'chipseq',
    proseq = 'proseq',
    biseq = 'biseq',
    cutandrun = 'cutandrun',
    atacseq = 'atacseq',
    elisa = 'elisa',
    simple_other_target = 'simple_other_target',
    unknown = 'unknown',
}

export type TemplateItem = {
    file_url: string;
    google_sheet_url: string;
};

/**
 * If a given analysis type has a results table
 * @param {AnalysisShortname} analysisType
 * @return {boolean}
 */
export const analysisHasDEGTable = (analysisType?: AnalysisShortname | null) => {
    if (!analysisType) {
        return false;
    }
    const degAnalysisTypes: AnalysisShortname[] = ['differential_expression', 'differential_binding'];
    return degAnalysisTypes.includes(analysisType);
};
/**
 * determines if the provided analysis type has a results table
 * @param {AnalysisShortname} analysisType
 * @returns {boolean}
 */
export const analysisHasResults = (analysisType: AnalysisShortname) => {
    const excludedTypes: AnalysisShortname[] = ['image', 'text', 'prism'];
    return !excludedTypes.includes(analysisType);
};

export interface ExperimentType extends DisplayableShortname<ExperimentTypeShortname> {
    display_name: string;
    shortname: ExperimentTypeShortname;
    short_display_name: string;
    description: string;
    tooltip: string;
    units?: MeasurementUnit[] | null;
    analysis_types?: AnalysisType[] | null;
    insights_tab_first?: boolean;
    insights_admin_phases: InsightsAdminPhase[] | null;
    assay_data_template: TemplateItem;
    sample_data_template: TemplateItem;
    default_report_url?: string | null;
    organisms?: Organism[] | null;
    is_automated_pipeline?: boolean;
    requires_pipeline_configuration?: boolean;
}

export type ExperimentTypeSummary = Pick<
    ExperimentType,
    'display_name' | 'short_display_name' | 'shortname' | 'requires_pipeline_configuration'
>;
export type SelectableExperimentType = ExperimentType & Filterable & Creatable;

export const unitPickerExperimentTypes: ExperimentTypeShortname[] = [
    ExperimentTypeShortname.scrnaseq,
    ExperimentTypeShortname.rnaseq,
    ExperimentTypeShortname.microarray,
    ExperimentTypeShortname.metabolomics,
    ExperimentTypeShortname.proteomics,
    ExperimentTypeShortname.chipseq,
    ExperimentTypeShortname.proseq,
    ExperimentTypeShortname.biseq,
    ExperimentTypeShortname.cutandrun,
    ExperimentTypeShortname.atacseq,
];

export const simpleExperimentTypes: ExperimentTypeShortname[] = [
    ExperimentTypeShortname.simple,
    ExperimentTypeShortname.elisa,
    ExperimentTypeShortname.simple_other_target,
];

export const isSimpleExperimentType = (experimentType?: ExperimentTypeShortname | null | undefined) => {
    if (!experimentType) {
        return false;
    }
    return simpleExperimentTypes.includes(experimentType);
};

export const hasFreeformUnits = (experimentType?: ExperimentTypeShortname | null) =>
    isSimpleExperimentType(experimentType);
