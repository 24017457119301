import { isDefined } from '@util/TypeGuards';
import prettyMilliseconds, { Options as PrettyMillisecondOptions } from 'pretty-ms';

export type Nullable<T> = T | null;
export type Optional<T> = T | null | undefined;
export type OptionalString = Optional<string>;
export type NullableObject<T> = { [K in keyof T]: T[K] | null };
export type OptionalFields<T, KEYS extends keyof T = keyof T> = Omit<T, KEYS> & {
    [K in KEYS]: T[K] | undefined | null;
};
export type NullableFields<T, KEYS extends keyof T = keyof T> = Omit<T, KEYS> & { [K in KEYS]: T[K] | null };

export const BYTES_IN_MB = 1_048_576;

export function isBlank(input?: OptionalString | string | unknown): boolean {
    if (!input || !(typeof input === 'string')) {
        return true;
    }
    return input.trim().length === 0;
}

export function isNotBlank(input?: OptionalString): input is string {
    return !isBlank(input);
}

export function blankToNull(input?: string | null | undefined | number): string | null {
    return isBlank(`${input}`) || !isDefined(input) ? null : `${input}`;
}

export function blankToUndefined(input?: string | null | undefined | number): string | undefined {
    return isBlank(`${input}`) || !isDefined(input) ? undefined : `${input}`;
}

export function stringToNumber(input: string | null | undefined): Nullable<number> {
    if (isBlank(input)) {
        return null;
    }
    const num = Number(input);
    if (isNaN(num)) {
        return null;
    }
    return num;
}

export function formatStringToNumberWithSeparator(value) {
    const number = Number(value);
    return isNaN(number) ? '' : number.toLocaleString();
}

export function stringToBoolean(input?: string | null | undefined | boolean): boolean {
    if (isBlank(`${input}`)) {
        return false;
    }
    return `${input ?? ''}`.trim().toLowerCase() === 'true';
}

/**
 * Format bytes as human-readable text.
 *
 * @param {number} _bytes Number of bytes.
 * @param {object} options Configuration options
 * @param {boolean} options.si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param {number} options.dp Number of decimal places to display.
 * @param {string} options.joiner Character to use to join the number and the units. Defaults to empty string.
 *
 * @return {string} Formatted string.
 */
export function humanFileSize(_bytes?: number | null, options?: { si?: boolean; dp?: number; joiner?: string }) {
    let bytes = _bytes ?? 0;
    const { si = true, dp = 1, joiner = '' } = options ?? {};
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return `${bytes} bytes`;
    }

    const units = si
        ? ['kb', 'mb', 'gb', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    return [bytes.toFixed(dp), units[u]].join(joiner);
}

/**
 * Replaces underscores with spaces. Does not alter casing. Casing can be modified using CSS as needed.
 * Adds special handing to do things like:
 *  - Capitalize the word "id" to ID.
 * @param {string} title
 * @return {string}
 */
export function formatTableHeader(title: string): string {
    const noUnderscores = title.replace(/_+/g, ' ').trim();

    return noUnderscores
        .split(' ')
        .map((word) => {
            if (word.toLowerCase() === 'id') {
                return 'ID';
            }
            if (word.toLowerCase() === 'min') {
                return 'Min.';
            }
            if (word.toLowerCase() === 'max') {
                return 'Max.';
            }
            if (word.toLowerCase() === 'ns') {
                return 'N.S.';
            }
            if (word.toLowerCase() === 'umi') {
                return 'UMI';
            }
            if (word === 'Neg Log10 Adj P Value') {
                return '-log10(FDR)';
            }
            return word;
        })
        .join(' ');
}

/**
 * Add comma separator to a number
 * @param {string|number} x
 * @return {string}
 */
export function numberWithCommas(x?: string | number): string {
    if (!isDefined(x) || isNaN(Number(x))) {
        return '';
    }
    return Number(x).toLocaleString();
}

export function toSafeFileName(input?: string | null): string {
    let parsed = (input ?? '').trim();
    // parsed = parsed.replace(/\s+/g, '_');
    parsed = parsed.replace(/[&\/\\#,+()$~%'":*?<>{}\s]+/g, '_');
    // parsed = parsed.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '_');
    return parsed.replace(/_+$/g, '');
}

export function removeUnderscores(input?: string | null): string {
    let parsed = (input ?? '').trim();
    parsed = parsed.replace(/_+/g, '');
    return parsed;
}

export const geneQueryParam = (value: string) => {
    return value.split(/(-|\.)/)[0] ?? value;
};

/**
 * Utility to format small numbers with a minimum value threshold under which a less-than sign will appear.
 * @param {number} value
 * @param {number} minThreshold
 * @param {number} decimals
 * @param {string} defaultPrefix
 * @return {string} the formatted value
 */
export const formatSmallNumber = ({
    value,
    minThreshold = 0.001,
    decimals = 3,
    defaultPrefix = '',
}: {
    value?: number;
    minThreshold?: number;
    decimals?: number;
    defaultPrefix?: string;
}) => {
    if (!isDefined(value)) {
        return '';
    }
    const prefix = value < minThreshold ? '<' : defaultPrefix;

    return `${prefix}${Math.max(value, minThreshold).toFixed(decimals)}`;
};

/**
 * Formats the given number of milliseconds into a human-readable string representation.
 * @param ms The number of milliseconds to format.
 * @param options Optional configuration options for formatting.
 * @returns The formatted string representation of the milliseconds.
 */
export const formatMilliseconds = (ms: number, options?: PrettyMillisecondOptions): string => {
    return prettyMilliseconds(ms, options);
};

/**
 * Removes the trailing occurrence of a specified string from the input string.
 *
 * @param input - The input string to remove the trailing string from.
 * @param value - The string to be removed from the end of the input string.
 * @returns The modified string with the trailing string removed, or null if the input is null.
 */
export const removeTrailingString = (input: string | null, value: string): string | null => {
    if (input?.endsWith(value)) {
        return input?.replace(new RegExp(`${value}$`), '');
    }
    return input;
};

/**
 * Removes leading and trailing whitespace from a string.
 * @param str - The input string.
 * @returns The input string with leading and trailing whitespace removed.
 */
export const removeWhitespace = (str: string) => {
    return str.replace(/^\s+|\s+$/g, '');
};

/**
 * Get a joiner string (a Determiner) based on the input word. Words that start with a vowel will return 'an', all else returns 'a'.
 * @param {string} word
 * @return {string}
 */
export const getSingleDeterminer = (word: string): string => {
    if (!word) return '';
    if (word.match(/^[aeiou].*/)) {
        return 'an';
    }
    return 'a';
};

type RoundToDecimalOptions = { decimals?: number; scientificNotationLower?: number; scientificNotationUpper?: number };
/**
 * Take a given number and round it to a fixed number of decimals.
 * Examples:
 *    [3 decimals]  1.0000 => 1
 *    [3 decimals] 1.00392834 => 1.004
 *
 * Note: the smallest Scientific Notation decimal is 1e-6. The max is 1e20
 *
 * @param {number} input
 * @param {RoundToDecimalOptions} options config options
 * @returns {number}
 */
export const roundToDecimal = (input?: number | null | undefined, options: RoundToDecimalOptions = {}): string => {
    const { decimals = 3, scientificNotationLower: lower = 1e-6, scientificNotationUpper: upper = 1e6 } = options;
    if (!isDefined(input)) {
        return '';
    }

    const scientificNotationLower = Math.max(lower, 1e-6);
    const scientificNotationUpper = Math.min(upper, 1e20);

    const absInput = Math.abs(input);

    if (input === 0) {
        return '0';
    }

    if (
        absInput < scientificNotationUpper &&
        absInput > scientificNotationLower &&
        absInput < Number.MAX_SAFE_INTEGER
    ) {
        const roundedTmp = Math.round(Number(`${input}e+${decimals}`));
        const formatted = +`${roundedTmp}e-${decimals}`;
        if (!Number.isNaN(formatted)) {
            return `${formatted}`;
        }
    }
    return input.toExponential(decimals);
};

/**
 * Takes a string array of numbers and sort it by ascending numeric value.
 * Examples:
 *    ['0.2', '1.2', '0.6'] => ['0.2', '0.6', '1.2']
 *
 * @param {string[]} stringNumbers
 * @returns {string[]}
 */
export const compareNumbers = (a: string, b: string) => {
    return parseFloat(a) - parseFloat(b);
};
/**
 * Sorts an array of string numbers in ascending order.
 *
 * @param stringNumbers - The array of string numbers to be sorted.
 * @returns The sorted array of string numbers.
 */
export const sortStringNumbers = (stringNumbers: string[]): string[] => {
    return stringNumbers.sort(compareNumbers);
};

/**
 * Takes a string and a character as input and returns the string after the character.
 * Example:
 *    const str = 'abc hello_123.com';
 *    // 👇️ 123.com
 *    console.log(afterCharacter(str, '_'));
 *
 * @param {string} string
 * @param {string} char
 * @returns {string}
 */
export const afterCharacter = (string: string, char: string) => {
    return string.slice(string.indexOf(char) + 1);
};

/**
 * Checks if the given hexadecimal color code represents white.
 * @param hex - The hexadecimal color code to check.
 * @returns A boolean indicating whether the color code represents white.
 */
export function isWhite(hex?: string): boolean {
    return hex === '#fff' || hex === '#ffffff';
}

/**
 * Converts a fraction string to a decimal number.
 *
 * @param str - The fraction string to convert. ex: '1/2'
 * @returns The decimal representation of the fraction. ex: 0.5
 */
export const fractionStrToDecimal = (str: string) => +str.split('/').reduce((p, c) => `${Number(p) / Number(c)}`);

/**
 * Rounds up a number to the nearest precision.
 *
 * @param number - The number to round up. ex: 0.126
 * @param precision - The precision to round up to. ex: 0.01
 * @returns The rounded up number. ex: 0.13
 */
export const roundUpToNearestPrecision = (number: number, precision: number) => {
    const multiplier = 1 / precision;
    return Math.ceil(number * multiplier) / multiplier;
};

/**
 * Finds evenly distributed values between a given range.
 *
 * @param {Object} options - The options for finding evenly distributed values.
 * @param {number} options.max - The maximum value of the range.
 * @param {number} options.min - The minimum value of the range.
 * @param {number} [options.multiplier=1] - The multiplier to apply to the calculated values.
 * @param {number} [options.precision=0.01] - The precision of the calculated values.
 * @param {boolean} [options.reverseOrder=false] - Specifies whether the values should be returned in reverse order.
 * @param {number} [options.toFixed] - The number of decimal places to round the values to.
 *
 * @returns {Array<number|string>} - An array of evenly distributed values.
 */
export const findEvenlyDistributedValues = ({
    max,
    min,
    multiplier = 1,
    precision = 0.01,
    reverseOrder = false,
    toFixed,
}: {
    max: number;
    min: number;
    multiplier?: number;
    precision?: number;
    reverseOrder?: boolean;
    toFixed?: number;
}) => {
    // Calculate the step size
    const stepSize = (max - min) / 4;

    // Generate three evenly distributed values between min and max
    const evenlyDistributedValues = Array.from({ length: 3 }, (_, index) => {
        let finalValue: number | string =
            roundUpToNearestPrecision(min + (index + 1) * stepSize, precision) * multiplier;
        if (toFixed !== undefined) {
            finalValue = finalValue.toFixed(toFixed);
        }
        return finalValue;
    });

    return reverseOrder ? evenlyDistributedValues.reverse() : evenlyDistributedValues;
};

/**
 * Capitalizes the first letter of each word in a string and replaces underscores with spaces.
 *
 * @param {string} input - The input string to be modified.
 * @returns {string} The modified string with capitalized words and replaced underscores.
 */
export function capitalizeWordsAndReplaceUnderscores(input) {
    // Split the input string into words
    const words = input.split('_');

    // Capitalize the first letter of each word and replace underscores with spaces
    const modifiedWords = words.map((word) => {
        // Capitalize the first letter of the word
        const capitalizedWord = word.charAt(0).toUpperCase() + word.slice(1);
        return capitalizedWord;
    });

    // Join the modified words back
    const result = modifiedWords.join(' ');

    return result;
}
